// @ts-check
import React, { useState, useEffect, useRef, useContext } from "react";
import _ from "lodash";
import { getApiCall, getBaseUrl, postApiCall, getLookupNames, getOneLineAddress } from "../../utilities/utilities";
import toastr from 'toastr';
import { AccountContext, MainContext } from "../../context_providers";

export default ({ history, location }) => {

    const { email } = useContext(AccountContext)
    const { lookupData, loadLookups, locationData, loadLocationData, locationQuery, setLocationQuery } = useContext(MainContext)

    const { rhcNumber, zone, name, page, category, locationType } = locationQuery;
    const { data, totalCount, totalPages, isLoading } = locationData;

    useEffect(() => {
        loadLookups();
    }, []);

    useEffect(() => {
        loadLocationData();
    }, [zone, rhcNumber, page, name, category, locationType])

    function onAdd() {
        history.push("/map");
    }

    function onGenerateReport() {
        let src = `${getBaseUrl()}/report/location`;
        window.location.href = `${src}`;
    }

    function search(e) {

        const { type, name, value } = e.target;
        if (type === "text" && e.keyCode === 13) {

            switch (name) {
                case "rhcNumber":
                    setLocationQuery({ ...locationQuery, rhcNumber: value, page: 1 })
                    break;
                case "name":
                    setLocationQuery({ ...locationQuery, name: value, page: 1 })
                    break;
                default:
                    break;
            }
        }

        if (type === "select-one") {
            switch (name) {
                case "category":
                    setLocationQuery({ ...locationQuery, category: value, page: 1 })

                    break;
                case "locationType":
                    setLocationQuery({ ...locationQuery, locationType: value, page: 1 })
                    break;

                case "zone":
                    setLocationQuery({ ...locationQuery, zone: value, page: 1 })
                    break;
                default:
                    break;
            }
        }
    }

    function prev() {
        setLocationQuery({ ...locationQuery, page: page - 1 })
    }

    function next() {
        setLocationQuery({ ...locationQuery, page: page + 1 })
    }

    function onRowClick(location) {
        history.push(`/location/${location._id}`)

    }

    function goToMap(e, { _id }) {
        e.stopPropagation();
        history.push(`/map?_id=${_id}`, { page, name, locationType, category })
    }

    function Pager() {
        return (
            <div className="pull-right mb-2" style={{ display: "flex", flexDirection: "row", alignItems: 'center', fontSize: 14 }}>
                <button disabled={page <= 1} className="btn btn-xs btn-success" onClick={prev} >{"< Prev"}</button>
                <span className="mx-2">Page: {page} of {totalPages}</span>
                <button disabled={page >= totalPages} className="btn btn-xs btn-success" onClick={next} >{"Next >"}</button>
            </div>
        )
    }

    return (
        <>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-lg-6 ">
                    <h2>Locations</h2>
                    <small>{`Total Count: ${totalCount || ""}`}</small>
                </div>
                <div className="col-lg-6 pt-4">
                    <div style={{ display: "flex", flexDirection: "row" }} className="pull-right">
                        <button
                            onClick={onAdd}
                            className="btn btn-primary pull-right" >
                            <i className="fa fa-map-marker" /> Use Map to add location</button>

                        <button
                            onClick={onGenerateReport}
                            className="btn pull-right btn-excel ml-2" >
                            <i className="fa fa-file-excel-o" /> Report</button>
                    </div>
                </div>
            </div>
            <div className="scrollable" style={{ height: "calc(100% - 110px)" }}>
                <div className="ibox animated fadeInLeft mt-3">
                    <div className="ibox-content" style={{ paddingBottom: 38 }}>
                        {Pager()}

                        <table className="display table table-striped table-bordered locations" style={{ width: "100%", fontSize: 12 }}>
                            <thead className="header">

                                <tr style={{ color: "white", fontSize: 10 }}>
                                    <th style={{ width: 30 }}></th>
                                    <th style={{ width: 150 }}>
                                        <input style={{ fontSize: 10, color: "black" }} defaultValue={rhcNumber} name="rhcNumber" className="form-control" placeholder="RHC Number" onKeyDown={search} />
                                    </th>
                                    <th style={{ width: 150 }}>
                                        <input style={{ fontSize: 10, color: "black" }} defaultValue={name} name="name" className="form-control" placeholder="Name" onKeyDown={search} />
                                    </th>
                                    <th style={{ width: 110 }}>
                                        <select style={{ color: "black", fontSize: 10, height: 29 }} name="locationType" className="form-control" value={locationType} onChange={search}>
                                            <option value="">All Types</option>
                                            {(lookupData.location_type || []).map((item, i) => <option key={i} value={item._id}>{item.name}</option>)}
                                        </select>
                                    </th>
                                    <th style={{ width: 110 }}>
                                        <select style={{ color: "black", fontSize: 10, height: 29 }} name="category" className="form-control" value={category} onChange={search}>
                                            <option value="">All Categories</option>
                                            {(lookupData.category || []).map((item, i) => <option key={i} value={item._id}>{item.name}</option>)}
                                        </select>
                                    </th>
                                    <th >
                                        Address
                                    </th>
                                    <th style={{ width: 110 }}>
                                        <select style={{ color: "black", fontSize: 10, height: 29 }} name="zone" className="form-control" value={zone} onChange={search}>
                                            <option value="">All Areas</option>
                                            {(lookupData.zone || []).map((item, i) => <option key={i} value={item._id}>{item.name}</option>)}
                                        </select>
                                    </th>
                                    <th style={{ width: 120 }}>
                                        Contact Person
                                    </th>

                                    <th>
                                    </th>
                                </tr>
                            </thead>
                            <tbody >
                                {data.map((item, i) => {
                                    return (
                                        <tr style={{ cursor: "pointer" }} key={i} onClick={() => onRowClick(item)}>
                                            <td>{i + 1 + ((page - 1) * 20)}</td>
                                            <td>{item.rhcNumber}</td>
                                            <td>{item.name}</td>
                                            <td>{item.locationType && item.locationType.name}</td>
                                            <td>{item.category && item.category.name}</td>
                                            <td>
                                                <span title={getOneLineAddress(item.feedingAddress1, item.feedingSuburb)}>{getOneLineAddress(item.feedingAddress1, item.feedingSuburb)}</span>
                                            </td>
                                            <td>
                                                {getLookupNames(lookupData.zone, item.zone)}
                                            </td>
                                            <td>{item.contactPerson}</td>
                                            {/* <td style={{ textAlign: "right" }}>{item.numberOfMeals}</td>
                                            <td><span title={getLookupNames(lookupData.day, item.days)}>{getLookupNames(lookupData.day, item.days)}</span></td>
                                            <td> <span title={getLookupNames(lookupData.mealtime, item.mealTimes)}>{getLookupNames(lookupData.mealtime, item.mealTimes)}</span> </td> */}
                                            <td onClick={(e) => goToMap(e, item)}>
                                                <div style={{ width: "100%", height: "100%" }} >
                                                    {
                                                        item.locationType && item.locationType.icon
                                                            ? <img style={{ height: 24, marginLeft: -5 }} src={item.locationType.icon} />
                                                            : <i style={{ color: "red" }} className="fa fa-2x fa-map-marker" />
                                                        // : <i style={{ color: "red" }} className="fa fa-2x fa-map-marker" />
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>

                        </table>
                        {Pager()}
                    </div>
                </div>
            </div>
        </>
    )
}