//@ts-check
import React, { useState, createContext, useReducer } from 'react';
import { getApiCall, postApiCall } from '../utilities/utilities';
import toastr from 'toastr';
export const MainContext = createContext(null);

export function MainProvider({ children }) {

    const [lookupData, setLookupData] = useState({});
    const [suppliers, setSuppliers] = useState([]);
    const [locations, setLocations] = useState([]);
    const [locationQuery, setLocationQuery] = useState({
        page: 1,
        name: "",
        zone: "",
        rhcNumber: "",
        locationType: "",
        category: ""
    })
    const [locationData, setLocationData] = useState({
        isLoading: false,
        data: [],
        totalPages: 0,
        totalCount: 0
    })

    const [stockQuery, setStockQuery] = useState({
        page: 1,
        stockItem: "",
        supplier: "",
    })
    const [stockData, setStockData] = useState({
        isLoading: false,
        data: [],
        totalPages: 0,
        totalCount: 0
    })

    const [deliveryQuery, setDeliveryQuery] = useState({
        page: 1,
        stockItem: "",
        location: "",
    })
    const [deliveryData, setDeliveryData] = useState({
        isLoading: false,
        data: [],
        totalPages: 0,
        totalCount: 0
    })


    async function loadLookups() {
        let res = await getApiCall(`/lookup`)
        setLookupData(res.data);
    }

    async function loadSuppliers() {
        let res = await getApiCall(`/supplier`)
        setSuppliers(res.data);
    }

    async function loadLocations() {
        let res = await getApiCall(`/location/lookup`)
        setLocations(res.data);
    }



    async function loadLocationData() {
        const { page, name, category, locationType, zone, rhcNumber } = locationQuery;
        setLocationData({ ...locationData, isLoading: true });
        if (page < 1) {
            return;
        }
        try {

            let res = await postApiCall(`/location/search`, {
                page,
                name,
                zone,
                rhcNumber,
                category,
                locationType
            })
            setLocationData({
                isLoading: false,
                data: res.data.data,
                totalPages: res.data.totalPages,
                totalCount: res.data.count
            })
        } catch (err) {
            toastr.error("Error Loading Data");
            setLocationData({ ...locationData, isLoading: false })
        }
    }

    async function loadStockData() {
        const { page, supplier, stockItem } = stockQuery;
        setStockData({ ...stockData, isLoading: true });
        if (page < 1) {
            return;
        }
        try {

            let res = await postApiCall(`/stock/search`, {
                page,
                stockItem,
                supplier
            })
            setStockData({
                isLoading: false,
                data: res.data.data,
                totalPages: res.data.totalPages,
                totalCount: res.data.count
            })
        } catch (err) {
            toastr.error("Error Loading Data");
            setStockData({ ...stockData, isLoading: false })
        }
    }

    async function loadDeliveryData() {
        const { page, location, stockItem } = deliveryQuery;
        setDeliveryData({ ...deliveryData, isLoading: true });
        if (page < 1) {
            return;
        }
        try {

            let res = await postApiCall(`/delivery/search`, {
                page,
                stockItem,
                location

            })
            setDeliveryData({
                isLoading: false,
                data: res.data.data,
                totalPages: res.data.totalPages,
                totalCount: res.data.count
            })
        } catch (err) {
            toastr.error("Error Loading Data");
            setDeliveryData({ ...deliveryData, isLoading: false })
        }
    }


    return (
        <MainContext.Provider
            value={{
                //state
                lookupData,
                locationData,
                locationQuery,
                suppliers,
                stockData,
                stockQuery,
                deliveryData,
                deliveryQuery,
                locations,

                //actions
                loadLookups,
                loadLocations,
                loadSuppliers,
                setLocationQuery,
                loadLocationData,
                setStockQuery,
                loadStockData,
                setDeliveryQuery,
                loadDeliveryData
            }}>
            {children}
        </MainContext.Provider>
    );

};