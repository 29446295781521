import React, { useEffect, useState } from 'react';
import { getApiCall, postApiCall, deleteApiCall, putApiCall } from '../../utilities/utilities'
import Control from '../../components/Control';
import ConfirmDelete from '../../components/ConfirmDelete';


function MapDetail({ match, history, location }) {


    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [active, setActive] = useState(false);
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [address3, setAddress3] = useState("");
    const [address4, setAddress4] = useState("");
    const [postal_code, setPostalCode] = useState("");
    const [phone, setPhone] = useState("");
    const [cell, setCell] = useState("");
    const [confirmDelete, setConfirmDelete] = useState(false)



    useEffect(() => {
        if (match.params.id !== "new") {
            loadInfo()
        }
    }, [])

    function onClose() {
        history.goBack();
    }



    async function loadInfo() {
        let res = await getApiCall(`/supplier/${match.params.id}`)
        const _supplier = res.data;

        setName(_supplier.name);
        setEmail(_supplier.email);
        setActive(_supplier.active);
        setActive(_supplier.active);
        setAddress1(_supplier.address1);
        setAddress2(_supplier.address2);
        setAddress3(_supplier.address3);
        setAddress4(_supplier.address4);
        setPostalCode(_supplier.postal_code);
        setCell(_supplier.cell);
        setPhone(_supplier.phone);




    }

    async function onDelete() {
        let res = await deleteApiCall(`/supplier/${match.params.id}`)
        onClose();
    }


    async function onSave() {

        let _supplier = {
            _id: match.params.id === "new" ? null : match.params.id,
            name: name.trim(),
            email: email.trim(),
            address1: address1.trim(),
            address2: address2.trim(),
            address3: address3.trim(),
            address4: address4.trim(),
            postal_code: postal_code.trim(),
            phone: phone.trim(),
            cell: cell.trim(),
            active: match.params.id === "new" ? true : active,
        }
        let res = await postApiCall("/supplier", _supplier);
        onClose();

    }



    return (
        <>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-lg-10 pl-4">
                    <h2>{"Food Supplier Details"}</h2>
                </div>

            </div>
            <div className="scrollable" style={{ height: "calc(100% - 110px)" }}>
                <div className="ibox animated fadeInLeft mt-3">
                    <div className="ibox-content">
                        <Control value={name} type="text" label="Supplier Name" onChange={e => setName(e.target.value)} />
                        <Control value={phone} type="text" label="Phone" onChange={e => setPhone(e.target.value)} />
                        <Control value={cell} type="text" label="Cell" onChange={e => setCell(e.target.value)} />
                        <Control value={email} type="email" label="Email" onChange={e => setEmail(e.target.value)} />
                        <Control value={address1} type="text" label="Address Line 1" onChange={e => setAddress1(e.target.value)} />
                        <Control value={address2} type="text" label="Address Line 2" onChange={e => setAddress2(e.target.value)} />
                        <Control value={address3} type="text" label="Address Line 3" onChange={e => setAddress3(e.target.value)} />
                        <Control value={address4} type="text" label="Address Line 4" onChange={e => setAddress4(e.target.value)} />
                        <Control value={postal_code} type="text" label="Postal Code" onChange={e => setPostalCode(e.target.value)} />
                        {match.params.id !== "new" && <Control value={active} type="checkbox" label="Active" onChange={e => setActive(e.target.checked)} />}
                        <div className="hr-line-dashed"></div>
                        <div className="form-group row">

                            <ConfirmDelete confirm={confirmDelete} onDelete={onDelete} onCancel={() => setConfirmDelete(false)}>
                                <div className="col-sm-4 col-sm-offset-2">
                                    <button className="btn btn-default" onClick={onClose}>Cancel</button>
                                    {match.params.id !== "new" && <button className="mx-1 btn btn-danger" onClick={() => setConfirmDelete(true)}>Delete</button>}
                                    <button className="ml-1 btn btn-primary" onClick={onSave}>Save Changes</button>
                                </div>
                            </ConfirmDelete>



                        </div>

                    </div>
                </div>
            </div>
        </>




    )

}

export default MapDetail