// @ts-check
import React, { useState, useEffect } from "react";
import _ from "lodash";
import ReactTable from 'react-table';
import { getApiCall } from "../../utilities/utilities";

function List({ history, location }) {

    const [data, setData] = useState([])

    useEffect(() => {
        loadFromAPI();
    }, []);


    async function loadFromAPI() {
        let res = await getApiCall("/supplier")
        let markers = res.data
        setData(markers);

    }

    function onAdd() {
        history.push("/supplier/new");
    }

    function onRowClick(e, t, rowInfo) {
        history.push(`/supplier/${rowInfo.original._id}`)
    }

    return (


        <>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-lg-10 pl-4">
                    <h2>{"Food Suppliers"}</h2>
                </div>
                <div className="col-lg-2 pt-4">
                    <button
                        onClick={onAdd}
                        className="btn btn-primary pull-right" >
                        <i className="fa fa-plus" /> Add Supplier</button>
                </div>
            </div>
            <div className="scrollable" style={{ height: "calc(100% - 110px)" }}>
                <div className="ibox animated fadeInLeft mt-3">
                    <div className="ibox-content">
                        <ReactTable
                            pageSize={15}
                            data={data}
                            className="table -striped"
                            getThProps={(state, rowInfo, column) => {
                                return {
                                    style: {
                                        backgroundColor: "black", color: "#eeeeee"
                                    }
                                }
                            }}
                            getTrProps={(state, rowInfo, column) => {
                                return {
                                    onClick: (e, t) => { onRowClick(e, t, rowInfo) },
                                    style: {
                                        cursor: "pointer"
                                    }
                                }
                            }}

                            columns={[
                                { Header: "Name", accessor: "name" },
                                { Header: "Phone", accessor: "phone" },
                                { Header: "Cell", accessor: "cell" },
                                { Header: "Line 1", accessor: "address1" },
                                { Header: "Line 2", accessor: "address2" },
                                { Header: "Line 3", accessor: "address3" },
                                { Header: "Line 4", accessor: "address4" },
                                { Header: "Postal Code", accessor: "postal_code" },
                                { Header: "Active", width: 60, style: { textAlign: "center" }, Cell: row => row.original.active ? <i className="fa fa-check" /> : <i className="fa fa-check-o" /> },
                            ]} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default List;
