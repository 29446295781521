import React from 'react';



function Modal({ children, size }) {
    return (
        <>
            <div className="modal inmodal fade show" style={{ display: "block" }} >
                <div className="modal-dialog" style={{ maxWidth: size, maxHeight: "90%", overflow: "auto" }}>
                    <div className="modal-content">

                        <div className="modal-body">
                            {children}
                        </div>


                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    )

}

export default Modal;