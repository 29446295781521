import axios from "axios";
import _ from "lodash";
import numeral from 'numeral';
import moment from "moment";
import FB from '../services/firebase';
const { api } = require("../services/config")

const baseUrl = api.base

const options = {
  headers: {

    "x-api-key": api.apiKey
  }
}

//Api call functions

export function getBaseUrl() {
  return baseUrl;
}
export function getApiCall(url) {
  return axios.get(baseUrl + url, options);
}

export function postApiCall(url, data) {
  return axios.post(baseUrl + url, data, options);
}

export function deleteApiCall(url) {
  return axios.delete(baseUrl + url, options);
}

export function putApiCall(url, data) {
  return axios.put(baseUrl + url, data, options);
}

export function resetPassword(email) {
  return FB.auth().sendPasswordResetEmail(email);
}


export function getOneLineAddress(address1, suburb) {
  if (address1 && suburb) {
    return address1 + ", " + suburb.split("|").join(",")
  }

  if (address1) return address1;

  if (suburb) return suburb.split("|").join(",")
}

export function getLookupNames(lookupCollection, itemCollection) {
  return (lookupCollection || []).filter(m => (itemCollection || []).indexOf(m._id) > -1).map(m => m.name).join(",\n")
}

export function getCurrentDate() {
  let date = new Date();
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let yyyy = date.getFullYear();

  dd = dd <= 9 ? "0" + dd : dd;
  mm = mm <= 9 ? "0" + mm : mm;
  return `${yyyy}-${mm}-${dd}`;
  //return date;
}
//productPrices is an array and date is an property
export function getPriceByDate(productPrices, date) {
  let prices = productPrices.filter(price => (price.date_from <= date));
  let sortedPrice = _.sortBy(prices, [prices.date_from]);
  let mostRecentPrice = _.isEmpty(sortedPrice) ? 0 : sortedPrice[sortedPrice.length - 1].price;
  return numeral(mostRecentPrice).format("#,##0.00");
}

export function calculateDataLimitForTable() {
  let screenHeight = window.screen.height;
  if (screenHeight <= 1080) {
    return 30
  } else if (screenHeight > 1080) {
    return 50
  }
}

//this function deep compares two objects
export function compareObject(obj1, obj2) {
  //Loop through properties in object 1
  for (var p in obj1) {
    //Check property exists on both objects
    if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

    switch (typeof obj1[p]) {
      //Deep compare objects
      case "object":
        if (!Object.compare(obj1[p], obj2[p])) return false;
        break;
      //Compare function code
      case "function":
        if (
          typeof obj2[p] == "undefined" ||
          (p !== "compare" && obj1[p].toString() !== obj2[p].toString())
        )
          return false;
        break;
      //Compare values
      default:
        if (obj1[p] !== obj2[p]) return false;
    }
  }

  //Check object 2 for any extra properties
  for (var p in obj2) {
    if (typeof obj1[p] == "undefined") return false;
  }
  return true;
}

export function compareAnArrayOfObjects(obj1, obj2) {
  for (var index = 0; index < obj2.length; index++) {
    if (!compareObject(obj1, obj2)) {
      return false;
    }
  }
  return true;
}

export function handleInputChange(state, setState, e) {
  setState({ ...state, [e.target.name]: e.target.value });
}

export function reactSelectCustomStyling(validate, value) {
  return ({
    container: (provided) => ({
      ...provided,
      display: 'inline-block',
      width: '100%',
      minHeight: '1px',
      height: "33px",
      textAlign: 'left',
      border: 'none',
    }),
    input: (provided) => ({
      ...provided,
      minHeight: '1px',
    }),
    placeholder: (provided) => ({
      ...provided,
      top: "14px",
      minHeight: '1px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      minHeight: '1px',
      paddingTop: '0',
      paddingBottom: '0',
      color: '#757575',
      height: "24px",
      marginBottom: "4px",
      marginTop: "5px"
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      minHeight: '1px',
      height: '24px',
      marginBottom: "4px",
      marginTop: "4px"

    }),
    indicatorContainer: (provided) => ({
      ...provided,
      minHeight: '1px',
      top: "0px"
    }),
    clearIndicator: (provided) => ({
      ...provided,
      minHeight: '1px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      minHeight: '1px',
      height: '33px',
      paddingTop: '0',
      paddingBottom: '0',
    }),
    singleValue: (provided) => ({
      ...provided,
      minHeight: '1px',
      paddingBottom: '2px',
      fontSize: "11px"
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      fontSize: "11px",
      backgroundColor: state.isSelected ? '#1ab394' : 'white',
      '&:hover': {
        color: 'white',
        backgroundColor: '#1ab394',
      }
    }),
    control: (provided) => ({
      ...provided,
      boxShadow: "none",
      fontSize: "13px",
      height: "33px",
      borderRadius: "0px",
      minHeight: "1px",
      borderColor: validate ?
        (value ? "#1ab394" : "#ed5565") :
        "#e5e6e7",
      '&:hover': {
        borderColor: '#1ab394',
      }
    }),
  })
}

export function getElement(string, splitBy, index) {
  return string.split(splitBy)[index] || false;

}


export function maxMonthRange(noOfMonths, startDate) {
  let newDate = new Date(startDate);
  newDate.setMonth(newDate.getMonth() + noOfMonths);
  return newDate;
}

export function minMonthRange(noOfMonths, endDate) {
  let newDate = new Date(endDate);
  newDate.setMonth(newDate.getMonth() - noOfMonths);
  return newDate;
}

export function formatDateForDisplay(date) {
  return moment(date).format("DD-MM-YYYY");
}

// export function sortBy(array, option) {
//     switch (option) {
//         case "firstName-asc":
//             return _.orderBy(array, [customer => customer.firstName.toLowerCase()], "asc");

//         case "firstName-desc":
//             return _.orderBy(array, [customer => customer.firstName.toLowerCase()], "desc");

//         case "lastName-asc":
//             return _.orderBy(array, [customer => customer.lastName.toLowerCase()], "asc");

//         case "lastName-desc":
//             return _.orderBy(array, [customer => customer.lastName.toLowerCase()], "desc");

//         case "prodName-asc":
//             return _.orderBy(array, [product => product.productName.toLowerCase()], "asc");

//         case "prodName-desc":
//             return _.orderBy(array, [product => product.productName.toLowerCase()], "desc");

//         case "price-asc":
//             return _.orderBy(array, [product => product.pricePerItem], "asc");

//         case "price-desc":
//             return _.orderBy(array, [product => product.pricePerItem], "desc");

//         case "stock-asc":
//             return _.orderBy(array, "itemsInStock", "asc");

//         case "stock-desc":
//             return _.orderBy(array, "itemsInStock", "desc");

//         case "date-asc":
//             return _.orderBy(array, "dateAdded", "asc");

//         case "date-desc":
//             return _.orderBy(array, "dateAdded", "desc");

//         default:
//             return array;
//     }
// }
