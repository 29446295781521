//@ts-check
import React, { useState, createContext, useEffect } from 'react';
import fb from '../services/firebase';
import Permissions from '../permissions'
import { getApiCall } from '../utilities/utilities';
import toastr from 'toastr';
import Routes from '../routes';

toastr.options = { positionClass: "toast-top-center" }


export const AccountContext = createContext(null);

export function AccountProvider({ children }) {

    const [display_name, setDisplayName] = useState("");
    const [role, setRole] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [isLoading, setLoading] = useState(true);
    const [isAuthenticating, setAuthenticating] = useState(false)
    const [roleRoutes, setRoleRoutes] = useState([]);


    useEffect(() => {
        const unsubscribe = onAuthStateChange();
        return () => {
            unsubscribe();
        };
    }, [])



    function resetAccount() {
        setDisplayName("");
        setRole("");
        setUsername("");
        setRoleRoutes([]);
    }

    function onAuthStateChange() {
        return fb.auth().onAuthStateChanged(async (fbUser) => {
            if (fbUser) {
                setEmail(fbUser.email)
                let res = await getApiCall(`/user/email/${fbUser.email}`)
                let appUser = res.data;
                if (appUser) {
                    setDisplayName(appUser.displayName);
                    setRole(appUser.role);
                    if (Permissions[appUser.role]) {
                        let permittedRoutes = Permissions[appUser.role].navs.map(n => n.route)
                        const roleRoutes = Routes.filter(route => {
                            return permittedRoutes.filter(permittedRoute => permittedRoute.indexOf(route.path) === 0).length > 0
                        })
                        setRoleRoutes(roleRoutes)
                    }
                } else {
                    setEmail("")
                    setDisplayName("");
                    setRole("")
                    setRoleRoutes([])
                }
            }
            setAuthenticating(false);
            setLoading(false);
        });
    }

    async function authenticate({ email, password }) {
        setAuthenticating(true);
        try {
            let user = await fb.auth().signInWithEmailAndPassword(email, password)
        } catch (err) {
            toastr.error(`Authentication Error ${err.message}`)
            setAuthenticating(false);
        }
    }

    async function logout() {
        try {
            await fb.auth().signOut();
            setUsername("");
            window.location = "/";

        } catch (err) {
            toastr.error(`Authentication Error ${err.message}`)
        }
    }

    function login() {

    }



    function setAccount(account) {
        setDisplayName(account.display_name);
        setRole(account.role);
        setUsername(account.username);
    }

    return (
        <AccountContext.Provider
            value={{
                //Actions
                setAccount,
                resetAccount,
                setRole,
                setUsername,
                setDisplayName,
                authenticate,
                logout,
                login,

                //State
                display_name,
                roleRoutes,
                isAuthenticating,
                role,
                isLoading,
                email,
                username,
            }}>
            {children}
        </AccountContext.Provider>
    );

};