import React, { useContext, useState } from "react";
import { AccountContext } from "../../context_providers";
export default function Login() {

    const { authenticate, isAuthenticating } = useContext(AccountContext)

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    function auth() {
        authenticate({ email, password });

    }
    return (
        <div className="middle-box text-center loginscreen animated fadeInDown">
            <div className="login-container" >

                <div className="logo-container">
                    <img src="/img/tracking_white.svg" style={{ width: 150 }} />
                </div>

                <h3 style={{ color: "white", fontSize: 35, marginTop: 20, marginBottom: 20 }}>Data Tracking</h3>


                <div className="form-group">
                    <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        required=""
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        onKeyDown={e => e.keyCode === 13 ? auth() : () => { }}

                        onChange={e => setPassword(e.target.value)}
                    />
                </div>
                {isAuthenticating ? <i className="fa fa-2x fa-spinner fa-spin ml-1" /> :
                    <button onClick={auth} className="btn btn-success block full-width m-b">Login</button>
                }

                <p className="m-t"> <small>Powered by Nanosoft's App Engine &copy; 2020</small> </p>
            </div>
        </div>

    )
}