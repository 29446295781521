import React, { useState } from 'react';
import Control from '../../components/Control';


function MapDetail({ onClose, onSave, lookupData }) {

    const [name, setName] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [category, setCategory] = useState("");

    return (
        <div style={{ position: "absolute", top: 140, borderRadius: 7, border: "2px solid #dedede", width: 400, padding: 10, backgroundColor: "white" }} >

            <div className="form-group row">
                <label className="col-sm-4 col-form-label">Name of Org</label>
                <div className="col-sm-8">
                    <input type="text" className="form-control" value={name || ""} autoFocus={true} onChange={e => setName(e.target.value)} />

                </div>
            </div>

            <div className="form-group row">
                <label className="col-sm-4 col-form-label">Contact Person</label>
                <div className="col-sm-8">
                    <input type="text" className="form-control" value={contactPerson || ""} onChange={e => setContactPerson(e.target.value)} />

                </div>
            </div>

            <Control split={[4, 8]}
                value={category || ""}
                options={(lookupData.category || []).map((item, i) => <option key={i} value={item._id}>{item.name}</option>)}
                type="select"
                label="Category"
                onChange={e => setCategory(e.target.value || null)}

            />

            <div className="form-group">
                <button className="btn btn-default btn-xs" onClick={onClose}>Cancel</button>
                <button className="ml-1 btn btn-success btn-xs" onClick={() => onSave(name, contactPerson, category)}>Save</button>
            </div>
        </div >
    )

}

export default MapDetail