import React, { useEffect, useState } from 'react';
import { getApiCall, postApiCall, deleteApiCall, putApiCall, resetPassword } from '../../../utilities/utilities'
import roles from '../../../services/role.json'
import Control from '../../../components/Control';
import toastr from 'toastr';



function IconItem({ src, selectedIcon, setIcon }) {
    return <img className="ml-1" src={`icons/${src}`} style={{ padding: 2, height: 30, cursor: "pointer", border: selectedIcon === src ? "2px solid green" : "" }} onClick={() => setIcon(src)} />

}

function MapDetail({ match, history, location }) {


    const [displayName, setDisplayName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [active, setActive] = useState("");
    const [role, setRole] = useState("");
    const [phone, setPhone] = useState("");
    const [key, setKey] = useState("");
    const [isResettingPassword, setIsResettingPassword] = useState(false);



    useEffect(() => {
        if (match.params.id !== "new") {
            loadInfo()
        }
    }, [])

    function onClose() {
        history.goBack();
    }



    async function loadInfo() {
        let res = await getApiCall(`/user/${match.params.id}`)
        let _user = res.data;
        setDisplayName(_user.displayName);
        setEmail(_user.email);
        setPhone(_user.phone);
        setKey(_user.key);
        setRole(_user.role);
        setActive(_user.active);
    }

    async function onDelete() {
        let res = await deleteApiCall(`/user/${match.params.id}`)
        onClose();
    }


    async function resetUserPassword() {

        setIsResettingPassword(true);
        try {
            let res = await resetPassword(email);
            setIsResettingPassword(false);
            toastr.info("Email Sent to User")
        } catch (err) {
            setIsResettingPassword(false);
            toastr.error("Error Sending Email to User");
        }


    }


    async function onSave() {

        let res;
        if (match.params.id !== "new") {
            res = await postApiCall("/user", {
                _id: match.params.id,
                displayName,
                email,
                role,
                key,
                phone,
                active

            })
        } else {
            res = await postApiCall("/user", {
                displayName,
                email,
                role,
                phone,
                password,
                active: true

            })
        }
        onClose();

    }

    return (
        <>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-lg-10 pl-4">
                    <h2>User Detail</h2>
                </div>

            </div>
            <div className="ibox animated fadeInLeft mt-3">
                <div className="ibox-content">

                    <Control value={displayName || ""} type="text" label="User Display Nam" onChange={e => setDisplayName(e.target.value)} />
                    <Control value={email || ""} type="email" label="Email Address" onChange={e => setEmail(e.target.value)} />
                    <Control value={phone || ""} type="text" label="Phone" onChange={e => setPhone(e.target.value)} />
                    <Control value={role || ""} type="select" label="Role" onChange={e => setRole(e.target.value)} options={roles.map((r, i) => <option key={i} value={r.value}>{r.name}</option>)} />
                    {match.params.id === "new" && <Control value={password || ""} type="text" label="Password" onChange={e => setPassword(e.target.value)} />}


                    {match.params.id !== "new" && <div className="form-group">
                        <label>Active</label>
                        <input
                            className="ml-1 f"
                            type="checkbox"
                            onChange={e => setActive(e.target.checked)}
                            checked={active ? "checked" : ""}
                        />

                    </div>
                    }

                    <div className="form-group">
                        {isResettingPassword ? <i className="fa fa-spinner fa-spin ml-1" /> : <button onClick={resetUserPassword} className="btn btn-success my-1"><i className="fa fa-key" /> Send Reset Password</button>}

                    </div>
                </div>

            </div>




            <div className="form-group pull-right">
                <button className="btn btn-default btn" onClick={onClose}>Cancel</button>
                {match.params.id !== "new" && <button className="mx-1 btn btn-danger" onClick={onDelete}>Delete</button>}
                <button className="ml-1 btn btn-success" onClick={onSave}>Save</button>
            </div>

        </>




    )

}

export default MapDetail