import React, { useContext, useState, useEffect } from "react";
import Table from 'react-table';
import Modal from '../../../components/modal';
import { getApiCall, postApiCall, deleteApiCall } from "../../../utilities/utilities";
import ConfirmDelete from '../../../components/ConfirmDelete'

const units = [
    { value: "kg", text: "Kg" },
    { value: "bag", text: "Bags" },
    { value: "m", text: "Meters" },
    { value: "lt", text: "Litres" },
    { value: "lv", text: "Loaves" },
]

function CategoryPane({ title, match }) {

    const [data, setData] = useState([])
    const [name, setName] = useState("")
    const [_id, setId] = useState("")
    const [active, setActive] = useState(false)
    const [order, setOrder] = useState(false)
    const [icon, setCatIcon] = useState("")
    const [confirmDelete, setConfirmDelete] = useState(false)

    const [unit, setUnit] = useState("")

    useEffect(() => {
        loadCategoryList();
    }, [match.params.lookup_id])





    async function loadCategoryList() {
        let res = await getApiCall(`/lookup/${match.params.lookup_id}`)
        setData(res.data);
    }


    let columns = [
        { Header: "Ord", width: 40, style: { textAlign: "right" }, Cell: row => row.original.order || "" },
        { Header: "Name", accessor: "name" },
        { Header: "Active", width: 60, style: { textAlign: "center" }, Cell: row => row.original.active ? <i className="fa fa-check" /> : "" },
    ];

    if (match.params.lookup_id === "category") {

        columns.push({
            Header: "Marker",
            width: 40,
            Cell: row => <img src={row.original.icon} style={{ height: 20 }} />
        })
    }


    if (match.params.lookup_id === "stock_item") {

        columns.push({
            Header: "Unit",
            width: 40,
            Cell: row => row.original.unit
        })
    }


    function onAdd() {
        setId("new");
        setName("");

    }

    function onClose() {
        loadCategoryList();
        setId("")

    }

    async function onDelete() {
        let res = await deleteApiCall(`/lookup/${match.params.lookup_id}/${_id}`)
        onClose();
    }

    async function onSave() {

        let _item = {
            _id: _id === "new" ? null : _id,
            name: name.trim(),
            icon,
            unit,
            order,
            active: _id === "new" ? true : active,
        }
        let res = await postApiCall(`/lookup/${match.params.lookup_id}`, _item);
        onClose();

    }

    async function onRowClick(e, t, rowInfo) {
        let res = await getApiCall(`/lookup/${match.params.lookup_id}/${rowInfo.original._id}`);
        let _item = res.data;
        setId(_item._id);
        setName(_item.name);
        setCatIcon(_item.icon || "");
        setUnit(_item.unit || "");
        setOrder(_item.order);
        setActive(_item.active);
    }

    let iconBase = "https://maps.google.com/mapfiles/ms/micons/";

    let icons = [
        "yellow-dot.png",
        "red-dot.png",
        "blue-dot.png",
        "purple-dot.png",
        "pink-dot.png",
        "orange-dot.png",
        "ltblue-dot.png",
        "green-dot.png"
    ]


    return (
        <div className="row p-3  p-0 h-100 scrollable" >
            <div className="col-md-12" >
                <div className="ibox">
                    <div className="ibox-title" style={{ backgroundColor: "white", color: "#676a6c" }}>


                        <strong>{title}</strong>

                        <button
                            onClick={onAdd}
                            className="btn btn-primary pull-right" >
                            <i className="fa fa-plus" /> Add Item</button>

                    </div>
                    <div className="ibox-content">

                        <Table
                            pageSize={10}
                            getTrProps={(state, rowInfo, column) => {
                                return {
                                    onClick: (e, t) => { onRowClick(e, t, rowInfo) },
                                    style: {
                                        cursor: "pointer"
                                    }
                                }
                            }}

                            className="table -striped border"
                            columns={columns}
                            data={data}
                            defaultPageSize={20}
                        />
                    </div>
                </div>
            </div>
            {_id && <Modal>


                <div className="form-group">
                    <label>Name</label>
                    <input
                        type="text"
                        placeholder="Name"
                        autoFocus={true}
                        className="form-control"
                        onChange={e => setName(e.target.value)}
                        value={name || ""}
                    />

                </div>


                {match.params.lookup_id === "stock_item" && <div className="form-group">
                    <label>Unit</label>

                    <select className="form-control" onChange={e => setUnit(e.target.value)} value={unit || ""}>
                        <option value="">Select</option>
                        {units.map((item, i) => <option value={item.value} key={i}>{item.text}</option>)}
                    </select>


                </div>
                }

                <div className="form-group">
                    <label>Display Order (Optional)</label>
                    <input
                        type="number"
                        placeholder="Display Order"
                        className="form-control"
                        onChange={e => setOrder(e.target.value)}
                        value={order || ""}
                    />

                </div>
                {_id !== "new" && <div className="form-group">
                    <label>Active</label>
                    <input
                        className="ml-1 f"
                        type="checkbox"
                        onChange={e => setActive(e.target.checked)}
                        checked={active ? "checked" : ""}
                    />

                </div>
                }



                {match.params.lookup_id === "category" && <div className="form-group mt-5">
                    <label>Marker</label>

                    <ul style={{ listStyle: "none" }}>

                        {icons.map((ic, i) => (
                            <li key={i}>
                                <label htmlFor={ic}>
                                    <img src={`${iconBase}${ic}`} style={{ height: 24 }} />
                                    <input id={ic} onChange={e => {
                                        setCatIcon(e.target.value)
                                    }} type='radio' name='icon' value={`${iconBase}${ic}`} checked={icon === `${iconBase}${ic}` ? "checked" : ""} />

                                </label>
                            </li>

                        ))}
                        <li>
                            <label >
                                <span>Default</span>
                                <input onChange={e => {
                                    setCatIcon("")
                                }} type='radio' name='icon' value={""} checked={!icon ? "checked" : ""} />

                            </label>
                        </li>

                    </ul>
                </div>
                }

                <div className="form-group row">
                    <ConfirmDelete confirm={confirmDelete} onDelete={onDelete} onCancel={() => setConfirmDelete(false)}>
                        <button className="btn btn-default btn" onClick={onClose}>Cancel</button>
                        {match.params.id !== "new" && <button className="mx-1 btn btn-danger" onClick={() => setConfirmDelete(true)}>Delete</button>}
                        <button className="ml-1 btn btn-success" onClick={onSave}>Save</button>
                    </ConfirmDelete>
                </div>
            </Modal>
            }
        </div>);

}

export default CategoryPane