// @ts-check
import React, { useState, useEffect } from "react";
import _ from "lodash";
import ReactTable from 'react-table';
import { getApiCall } from "../../../utilities/utilities";

function List({ history, location }) {

    const [data, setData] = useState([])

    useEffect(() => {
        loadFromAPI();
    }, []);


    async function loadFromAPI() {
        let res = await getApiCall("/user")
        let markers = res.data
        setData(markers);

    }



    function onAdd() {
        history.push("/admin/user/new");
    }


    function onRowClick(e, t, rowInfo) {
        history.push(`/admin/user/${rowInfo.original._id}`)
    }


    return (


        <>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-lg-10 pl-4">
                    <h2>Users</h2>
                </div>
                <div className="col-lg-2 pt-4">
                    <button
                        onClick={onAdd}
                        className="btn btn-primary pull-right" >
                        <i className="fa fa-plus" /> Add User</button>
                </div>
            </div>
            <div className="ibox animated fadeInLeft mt-3">
                <div className="ibox-content">
                    <ReactTable
                        pageSize={10}
                        data={data}
                        className="table -striped"
                        getThProps={(state, rowInfo, column) => {
                            return {
                                style: {
                                    backgroundColor: "black", color: "#eeeeee"
                                }
                            }
                        }}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                onClick: (e, t) => { onRowClick(e, t, rowInfo) },
                                style: {
                                    cursor: "pointer"
                                }
                            }
                        }}

                        columns={[
                            { Header: "Display Name", accessor: "displayName" },
                            { Header: "Email", accessor: "email" },
                            { Header: "Phone", accessor: "phone" },
                            { Header: "Role", accessor: "role" },
                            { Header: "Active", Cell: row => row.original.active && <i className="fa fa-check" /> },
                        ]} />
                </div>
            </div>

        </>
    );
}

export default List;
