import React, { useEffect, useState, useContext } from 'react';
import { getApiCall, postApiCall, deleteApiCall, putApiCall } from '../../utilities/utilities'
import LoadingIndicator from '../../components/LoadingIndicator';
import Control from '../../components/Control';
import { MainContext } from "../../context_providers";
import { trim } from 'lodash';
import ConfirmDelete from '../../components/ConfirmDelete'
import moment from 'moment';
import toastr from 'toastr';



function StockDetail({ match, history }) {

    const { lookupData, suppliers } = useContext(MainContext)
    const [stockItem, setStockItem] = useState("");
    const [qty, setQty] = useState("");
    const [supplier, setSupplier] = useState("");
    const [unit, setUnit] = useState("");
    // const [batchQty, setBatchQty] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [dateCaptured, setDateCaptured] = useState("");
    const [note, setNote] = useState("");
    const [confirmDelete, setConfirmDelete] = useState(false)


    useEffect(() => {
        if (match.params.id !== "new") {
            loadInfo()
        }
    }, [])

    function onClose() {
        history.goBack();
    }

    async function loadInfo() {
        setIsLoading(true)
        try {
            let res = await getApiCall(`/stock/${match.params.id}`)
            let { qty, note, unit, stockItem, supplier, dateCaptured, batchQty } = res.data;
            setQty(qty);
            setSupplier(supplier)
            setUnit(unit)
            setNote(note)
            setDateCaptured(dateCaptured);
            setStockItem(stockItem);
            setIsLoading(false)

        } catch (err) {
            toastr.error("Error Loading Stock Entry")
            setIsLoading(false)
        }
    }


    async function onDelete() {
        let res = await deleteApiCall(`/stock/${match.params.id}`)
        onClose();
    }



    async function onSave() {

        let _StockEntry = {
            _id: match.params.id === "new" ? null : match.params.id,
            stockItem,
            supplier,
            note,
            // batchQty: batchQty || 1,
            qty,
            unit,
            dateCaptured: moment()
        }

        let res = await postApiCall("/stock", _StockEntry)
        onClose();
    }

    return (
        <>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-lg-10 pl-4">
                    <h2>{"Stock Entries"}</h2>
                </div>
            </div>
            <div className="scrollable" style={{ height: "calc(100% - 110px)" }}>
                <div className="ibox animated fadeInLeft mt-3">

                    {isLoading ?
                        <div className="ibox-content">
                            <LoadingIndicator size={30} />
                        </div>
                        :
                        <div className="ibox-content">
                            <Control split={[2, 10]}
                                value={stockItem}
                                options={(lookupData.delivery_item || []).map((item, i) => <option key={i} value={item._id}>{item.name}</option>)}
                                type="select" label="Stock Item" onChange={e => setStockItem(e.target.value || null)}
                            />


                            <Control split={[2, 10]}
                                value={qty}
                                type="number" label="Qty" onChange={e => setQty(e.target.value || null)}
                            />
                            {/* 
                            <Control split={[2, 10]}
                                value={batchQty}
                                type="number" label="Batch Qty" onChange={e => setBatchQty(e.target.value || null)}
                            /> */}

                            <Control split={[2, 10]}
                                value={supplier}
                                options={suppliers.map((item, i) => <option key={i} value={item._id}>{item.name}</option>)}
                                type="select" label="Supplier" onChange={e => setSupplier(e.target.value || null)}
                            />

                            <Control split={[2, 10]}
                                value={dateCaptured ? moment(dateCaptured).format("DD-MMM-YYYY  HH:mm:ss") : moment().format("DD-MMM-YYYY HH:mm:ss")}
                                type="label" label="Date Time Captured"
                            />

                            <Control split={[2, 10]}
                                onChange={e => setNote(e.target.value)}
                                value={note}
                                type="textarea" label="Note"
                            />
                            {qty < 0 && !note && <small style={{ color: "red" }}>Note required for negative entry</small>}




                            <div className="hr-line-dashed"></div>
                            <div className="form-group row">
                                {/* <ConfirmDelete confirm={confirmDelete} onDelete={onDelete} onCancel={() => setConfirmDelete(false)}> */}
                                <div className="col-sm-12 col-sm-offset-2">
                                    <button className="btn btn-default" onClick={onClose}>Cancel</button>
                                    {/* {match.params.id !== "new" && <button className="mx-1 btn btn-danger" onClick={() => setConfirmDelete(true)}>Delete</button>} */}

                                    <button disabled={(!stockItem || !qty) || (qty < 0 && trim(note).length === 0)} className="ml-1 btn btn-primary" onClick={onSave}>Save Changes</button>
                                </div>
                                {/* </ConfirmDelete> */}

                            </div>

                        </div>
                    }
                </div>
            </div>
        </>




    )

}

export default StockDetail