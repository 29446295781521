import React, { useContext } from 'react';
import { Link, Switch, Route } from "react-router-dom";



function ListItemView({ style = {}, data, ItemTemplate, fetchingData }) {
    return (
        <ul className="list-group elements-list border-right" style={style}>
            <div className="scrollable">
                {fetchingData ? (
                    <div className="text-center">
                        <i className="fa fa-spinner fa-spin ml-1" />
                    </div>
                ) : !_.isEmpty(data) ? (
                    data.map((item, i) => <ItemTemplate key={i} {...item} />)
                ) : (
                            <div className="text-center">
                                <h3>No Data Found</h3>
                            </div>
                        )}
            </div>
        </ul>
    );
}

function ReportItemTemplate({
    match,
    onReportSelected,
    isLoading,
    title,
    path
}) {
    return (
        <li onClick={() => onReportSelected(path)} className="list-group-item">
            <div
                className={
                    "/admin/lookup/" + match.params.lookup_id === path
                        ? "nav-link active pt-0"
                        : "nav-link pt-0"
                }
            >
                <div className="row">
                    {isLoading && match.params.reportId === path ? (
                        <div className="pl-4">
                            <h3>
                                Loading Lookup Data<i className="fa fa-spinner fa-spin ml-1"></i>
                            </h3>
                        </div>
                    ) : (
                            <>
                                <div className="col-md-12 truncate-string pt-3" title={title}>
                                    <strong>{title}</strong>
                                </div>
                            </>
                        )}
                </div>
            </div>
        </li>
    )
}


function ReportList({ history, roleRoutes }) {


    function onReportSelected(url) {
        history.push(`${url}`)
    }

    return (

        <>
            {/* <div className="row column-header border-bottom">
                <div className="col-md-10 pr-0">
                    <strong>Categories</strong>
                </div>

            </div> */}

            <ListItemView
                style={{ height: 'calc(100% - 80px)' }}
                data={roleRoutes}
                ItemTemplate={props => (
                    <Switch>
                        <Route
                            exact
                            path="/admin/lookup"
                            render={renderProps => (
                                <ReportItemTemplate
                                    onReportSelected={onReportSelected}
                                    {...props}
                                    {...renderProps}
                                />
                            )}
                        />
                        <Route
                            path="/admin/lookup/:lookup_id"
                            render={renderProps => (
                                <ReportItemTemplate
                                    onReportSelected={onReportSelected}
                                    {...props}
                                    {...renderProps}
                                />
                            )}
                        />
                    </Switch>
                )}
            />
        </>

    )
}

export default ReportList;