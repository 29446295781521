import React from "react";
import { Route, Redirect } from "react-router-dom";
import List from './list';
import LookupPane from "./lookup_pane";

const routes = [
    { title: "Meals", path: "/admin/lookup/meal" },
    { title: "Titles", path: "/admin/lookup/title" },
    { title: "Designations", path: "/admin/lookup/designation" },
    { title: "Types", path: "/admin/lookup/location" },
    { title: "Meal Times", path: "/admin/lookup/mealtime" },
    { title: "Age Groups", path: "/admin/lookup/age_type" },
    { title: "Days", path: "/admin/lookup/day" },
    { title: "Categories", path: "/admin/lookup/category" },
    { title: "Stock Items", path: "/admin/lookup/stock_item" },
    { title: "Areas", path: "/admin/lookup/zone" },

]




function ReportNav() {

    function getTitle(path) {
        let _pathRoutes = routes.filter(r => r.path === "/admin/lookup/" + path);
        return _pathRoutes.length > 0 ? _pathRoutes[0].title : "";
    }

    return (
        <div className="fh-breadcrumb">
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-lg-10 pl-4">
                    <h2>System Lookup Data</h2>
                </div>
                <div className="col-lg-2">

                </div>
            </div>
            <div className="fh-column">
                <Route path="/admin/lookup" render={props => <List {...props} roleRoutes={routes} />} />
            </div>
            <Redirect path="/admin/lookup" to="/admin/lookup/meal" />
            <Route path="/admin/lookup/:lookup_id" render={props => <LookupPane title={getTitle(props.match.params.lookup_id)} {...props} />} />
        </div>
    );
}

export default ReportNav;