import React from 'react';

function ConfirmDelete({ children, confirm, onDelete, onCancel }) {

    if (confirm) {
        return (
            <div className="col-sm-12">
                <button className="mx-1 btn btn-default" onClick={onCancel}>Cancel</button>
                <button className="ml-1 btn btn-danger" onClick={onDelete}>Yes Delete!</button>
            </div>
        );
    }

    return children;

}

export default ConfirmDelete;