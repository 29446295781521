import React, { useContext, useState } from "react";
import { AccountContext } from "../context_providers";
import Login from '../views/account/login'



function NavHeader({ children }) {
  return (
    <div className="row border-bottom">

      <nav
        className="navbar navbar-static-top"
        role="navigation"
        style={{
          marginBottom: 0,
          backgroundColor: "#f3f3f4",
          height: "43px",



          justifyContent: "flex-end",
        }}
      >

        {children}

      </nav>
    </div>

  )


}

export default function TopNav({ history, showLogo = false }) {
  const { logout, authenticate, email, display_name } = useContext(AccountContext);
  const [password, setPassword] = useState("")
  const [emailString, setEmail] = useState("");
  const [showLogin, setShowLogin] = useState("");

  function auth() {
    authenticate({ email: emailString, password })
  }

  if (showLogin) {
    return (

      <NavHeader>
        <ul
          className="nav navbar-top-links navbar-right"
          style={{ height: "30px", padding: "0", marginTop: 4 }}
        >
          <li>
            <input type="text" placeholder="Email" value={emailString} onChange={e => setEmail(e.target.value)} />
            <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} onKeyDown={e => e.keyCode === 13 ? auth() : () => { }} />
          </li>
          <li>
            <button className="btn ml-2 btn-primary" onClick={auth}>
              <i className="fa fa-sign-in" /> {"Log in"}
            </button>
            <button className="btn mx-2 login btn-default" onClick={() => setShowLogin(false)}>
              {"Cancel"}
            </button>
          </li>
        </ul>
      </NavHeader>

    )
  }

  if (email) {

    return (

      <NavHeader>
        <ul
          className="nav navbar-top-links navbar-right"
          style={{ height: "30px", padding: "0", marginTop: 4 }}
        >
          <li> <span style={{ fontWeight: "bold" }}>{display_name}</span></li>
          <li>
            <button className="btn ml-2 login" onClick={logout}>
              <i className="fa fa-sign-out" /> {"Log out"}
            </button>
          </li>
        </ul>
      </NavHeader>
    )

  }

  return <Login />

  // return (
  //   <>
  //     <p className="secure">
  //       <button className="btn" onClick={() => setShowLogin(true)}>
  //         <i className="fa fa-lock" />
  //       </button>
  //     </p>

  //     {showLogo && <img className="guest" src="/img/grclogo.png" />}

  //   </>
  // );



}


