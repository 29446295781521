import React, { useState, useContext } from "react";
import SideNavHeader from "../components/sidenavheader";
import { NavLink, Route } from "react-router-dom";
import _ from "lodash";
import { MainContext } from "../context_providers";

const navItems = require("../nav.json");

export default function Nav({ location }) {


  const { setLocationQuery, setStockQuery, setDeliveryQuery } = useContext(MainContext)


  const [menuClicked, setMenuClicked] = useState(false);


  function isMatch(route) {
    return _.startsWith(location.pathname, route)

  }


  return (
    <nav className="navbar-default navbar-static-side" role="navigation">
      <div className="sidebar-collapse">
        <SideNavHeader />
        <ul className="nav metismenu scrollable" id="side-menu">
          <li className={isMatch("/map") && !menuClicked ? "active" : ""} >
            <NavLink to="/map" onClick={e => {
              setMenuClicked(false)
            }} >
              <i className="fa fa-map-marker" />{" "}
              <span className="nav-label">Map</span>
            </NavLink>
          </li>
          <li className={isMatch("/location") && !menuClicked ? "active" : ""}>
            <NavLink to="/location" onClick={e => {
              setLocationQuery({ name: "", category: "", locationType: "", category: "", page: 1 })
              setMenuClicked(false)
            }}>
              <i className="fa fa-map" />{" "}
              <span className="nav-label">Locations</span>
            </NavLink>
          </li>

          <li className={isMatch("/supplier") && !menuClicked ? "active" : ""} >
            <NavLink to={"/supplier"}>
              <i className="fa fa-user" />{" "}
              <span className="nav-label">Suppliers</span>
            </NavLink>
          </li>

          <li className={isMatch("/stock") && !menuClicked ? "active" : ""} >
            <NavLink to={"/stock"} onClick={e => {
              setStockQuery({ stockItem: "", supplier: "", page: 1 })
            }}>
              <i className="fa fa-cube" />{" "}
              <span className="nav-label">Stock</span>
            </NavLink>
          </li>
          <li className={isMatch("/delivery") && !menuClicked ? "active" : ""} >
            <NavLink to={"/delivery"} onClick={e => {
              setDeliveryQuery({ stockItem: "", location: "", page: 1 })
            }}>
              <i className="fa fa-truck" />{" "}
              <span className="nav-label">Distribution</span>
            </NavLink>
          </li>

          <li className={menuClicked ? "active" : ""}  >
            <a href="/admin" onClick={e => {
              e.preventDefault()
              setMenuClicked(!menuClicked)
            }}>
              <i className="fa fa-bars" />{" "}
              <span className="nav-label">Admin</span>
              <span className="fa arrow"></span>
            </a>
            {menuClicked && <ul className="nav nav-second-level collapse in" aria-expanded="true" >
              <li className={isMatch("/admin/user") ? "active" : ""} >
                <NavLink to={"/admin/user"}>
                  <i className="fa fa-users" />{" "}
                  <span className="nav-label">Users</span>
                </NavLink>
              </li>

              <li className={isMatch("/admin/lookup") ? "active" : ""} >
                <NavLink to={"/admin/lookup"}>
                  <i className="fa fa-file" />{" "}
                  <span className="nav-label">Lookup Data</span>
                </NavLink>
              </li>
            </ul>
            }
          </li>



        </ul>
      </div>
    </nav>
  );


}
