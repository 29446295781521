import React, { useEffect, useState } from 'react';
import { getApiCall, postApiCall, deleteApiCall, getBaseUrl } from '../../utilities/utilities'
import Control from '../../components/Control';
import ConfirmDelete from '../../components/ConfirmDelete'
import Select from 'react-select';
import toastr from 'toastr';
import LoadingIndicator from '../../components/LoadingIndicator';

function MapDetail({ match, history, location }) {




    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [npoNumber, setNPONumber] = useState("");
    const [rhcNumber, setRHCNumber] = useState("");
    const [feedingStationLocation, setFeedingStationLocation] = useState("");
    const [cookingLocation, setCookingLocation] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [targetArea, setTargetArea] = useState("");
    const [numberOfMeals, setNumberOfMeals] = useState(0);
    const [frequency, setFrequency] = useState("");
    const [mealTimes, setMealTimes] = useState([]);
    const [days, setDays] = useState([]);
    const [mealTypes, setMealTypes] = useState([]);
    const [designation, setDesignation] = useState("")
    const [lookupData, setLookupData] = useState({});
    const [feedingAddress1, setFeedingAddress1] = useState("");
    const [feedingAddress2, setFeedingAddress2] = useState("");
    const [feedingCity, setFeedingCity] = useState("");
    const [feedingSuburb, setFeedingSuburb] = useState("");
    const [feedingPostalCode, setFeedingPostalCode] = useState("");
    const [cookingAddress1, setCookingAddress1] = useState("");
    const [cookingAddress2, setCookingAddress2] = useState("");
    const [cookingCity, setCookingCity] = useState("");
    const [cookingSuburb, setCookingSuburb] = useState("");
    const [zone, setZone] = useState("");
    const [cookingPostalCode, setCookingPostalCode] = useState("");
    const [comments, setComments] = useState("");
    const [title, setTitle] = useState("");
    const [locationType, setLocationType] = useState("");
    const [category, setCategory] = useState("");
    const [agesServed, setAgesServed] = useState([]);
    const [icon, setIcon] = useState("");
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [tab, setTab] = useState("1");
    const [confirmDelete, setConfirmDelete] = useState(false)

    useEffect(() => {
        loadLookup();
        if (match.params.id !== "new") {
            loadLocation()
        } else {
            setLng(location.state.lng)
            setLat(location.state.lat)
        }
    }, [match.params.id])

    function onClose() {
        history.replace("/location");
    }

    async function loadLookup() {
        let res = await getApiCall(`/lookup`)
        setLookupData(res.data);
    }

    async function loadLocation() {
        setIsLoading(true)
        try {
            let res = await getApiCall(`/location/${match.params.id}`)
            let _marker = res.data;
            setLat(_marker.lat)
            setLng(_marker.lng)
            setRHCNumber(_marker.rhcNumber)
            setName(_marker.name)
            setEmail(_marker.email)
            setZone(_marker.zone)
            setNPONumber(_marker.npoNumber)
            setName(_marker.name)
            setIcon(_marker.icon)
            setLocationType(_marker.locationType)
            setCategory(_marker.category)
            setFeedingStationLocation(_marker.feedingStationLocation)
            setCookingLocation(_marker.cookingLocation)
            setContactPerson(_marker.contactPerson)
            setContactNumber(_marker.contactNumber)
            setTargetArea(_marker.targetArea)
            setTitle(_marker.title)
            setDesignation(_marker.designation)
            setNumberOfMeals(_marker.numberOfMeals)
            setFrequency(_marker.frequency)
            setAgesServed(_marker.agesServed)
            setDays(_marker.days)
            setMealTimes(_marker.mealTimes)
            setComments(_marker.comments)
            setMealTypes(_marker.mealTypes)
            setFeedingAddress1(_marker.feedingAddress1)
            setFeedingAddress2(_marker.feedingAddress2)
            setFeedingCity(_marker.feedingCity)
            setFeedingSuburb(_marker.feedingSuburb)
            setFeedingPostalCode(_marker.feedingPostalCode)
            setCookingAddress1(_marker.cookingAddress1)
            setCookingAddress2(_marker.cookingAddress2)
            setCookingCity(_marker.cookingCity)
            setCookingSuburb(_marker.cookingSuburb)
            setCookingPostalCode(_marker.cookingPostalCode)
            setIsLoading(false);
        } catch (err) {
            toastr.error("Error Loading Details");
            setIsLoading(false);
        }

    }

    async function onDelete() {
        let res = await deleteApiCall(`/location/${match.params.id}`)
        onClose();
    }


    async function onSave() {
        let _marker = {
            name,
            rhcNumber,
            feedingStationLocation,
            cookingLocation,
            contactPerson,
            contactNumber,
            targetArea,
            numberOfMeals,
            mealTypes,
            locationType,
            zone,
            category,
            frequency,
            email,
            npoNumber,
            mealTimes,
            days,
            title,
            comments,
            agesServed,
            designation,
            feedingAddress1,
            feedingAddress2,
            feedingCity,
            feedingPostalCode,
            feedingSuburb,
            cookingAddress1,
            cookingAddress2,
            cookingCity,
            cookingPostalCode,
            cookingSuburb,
            icon,
            _id: match.params.id === "new" ? null : match.params.id,
            lat,
            lng
        }


        let res = await postApiCall("/location", _marker)
        onClose();

    }

    let mealTimesOptions = (lookupData.mealtime || []).map((m => ({ value: m._id, label: m.name })))
    let zoneOptions = (lookupData.zone || []).map((m => ({ value: m._id, label: m.name })))
    let mealOptions = (lookupData.mealtype || []).map((m => ({ value: m._id, label: m.name })))
    let dayOptions = (lookupData.day || []).map((m => ({ value: m._id, label: m.name })))
    let agesOptions = (lookupData.age_type || []).map((m => ({ value: m._id, label: m.name })))


    function onTabChange(e) {
        e.preventDefault();
        setTab(e.target.name)
    }

    return (

        <>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-lg-10 pl-4">
                    <h2>{"Location Details"}</h2>
                </div>
            </div>
            <div className="scrollable" style={{ height: "calc(100% - 110px)" }}>
                <div className="ibox animated fadeInLeft mt-3">

                    {isLoading ?
                        <div className="ibox-content">
                            <LoadingIndicator size={30} />
                        </div>
                        :

                        <div className="ibox-content">
                            <div className="tabs-container">

                                <ul className="nav nav-tabs" role="tablist">
                                    <li><a onClick={onTabChange} className={"nav-link " + (tab === "1" ? "active" : "")} data-toggle="tab" name="1"> General Info</a></li>
                                    <li><a onClick={onTabChange} href="#" className={"nav-link " + (tab === "2" ? "active" : "")} data-toggle="tab" name="2">Address</a></li>
                                </ul>

                                <div className="tab-content">
                                    <div role="tabpanel" id="tab-1" className={"tab-pane " + (tab === "1" ? "active" : "")}>
                                        <div className="panel-body p-5">
                                            <div className="row">
                                                <div className="col-md-6">

                                                    <Control split={[3, 9]} value={name} type="text" label="Name of Patient" onChange={e => setName(e.target.value)} />
                                                    <Control split={[3, 9]} value={rhcNumber} type="text" label="RHC Number" onChange={e => setRHCNumber(e.target.value)} />
                                                    <Control split={[3, 9]} value={locationType} options={(lookupData.location_type || []).map((item, i) => <option key={i} value={item._id}>{item.name}</option>)}
                                                        type="select" label="Type" onChange={e => setLocationType(e.target.value || null)} />
                                                    <Control split={[3, 9]} value={category} options={(lookupData.category || []).map((item, i) => <option key={i} value={item._id}>{item.name}</option>)}
                                                        type="select" label="Category" onChange={e => setCategory(e.target.value || null)} />
                                                    {/* <Control split={[3, 9]} value={npoNumber} type="text" label="NPO Number" onChange={e => setNPONumber(e.target.value)} /> */}
                                                </div>
                                                <div className="col-md-6">
                                                    <Control split={[3, 9]} value={title} options={(lookupData.title || []).map((item, i) => <option key={i} value={item._id}>{item.name}</option>)}
                                                        type="select" label="Title" onChange={e => setTitle(e.target.value || null)} />
                                                    <Control split={[3, 9]} value={contactPerson} type="text" label="Contact Person" onChange={e => setContactPerson(e.target.value)} />
                                                    {/* <Control split={[3, 9]} value={designation} options={(lookupData.designation || []).map((item, i) => <option key={i} value={item._id}>{item.name}</option>)}
                                                        type="select" label="Designation" onChange={e => setDesignation(e.target.value || null)} /> */}
                                                    <Control split={[3, 9]} value={contactNumber} type="text" label="Contact Number" onChange={e => setContactNumber(e.target.value)} />
                                                    <Control split={[3, 9]} value={email} type="email" label="Email" onChange={e => setEmail(e.target.value)} />

                                                    {/* <Control split={[3, 9]} value={numberOfMeals} type="text" label="Meals per serving" onChange={e => setNumberOfMeals(e.target.value)} /> */}

                                                    {/* <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">Days</label>
                                                        <div className="col-sm-9">
                                                            <Select
                                                                value={dayOptions.filter(b => (days || []).indexOf(b.value) > -1)}
                                                                isMulti
                                                                onChange={selectedOptions => setDays(selectedOptions ? (selectedOptions || []).map(s => s.value) : null)}
                                                                options={dayOptions}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                            />
                                                        </div>
                                                    </div> */}

                                                    {/* <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">Meals</label>
                                                        <div className="col-sm-9">
                                                            <Select
                                                                value={mealOptions.filter(b => (mealTypes || []).indexOf(b.value) > -1)}
                                                                isMulti
                                                                onChange={selectedOptions => setMealTypes(selectedOptions ? (selectedOptions || []).map(s => s.value) : null)}
                                                                options={mealOptions}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                            />
                                                        </div>
                                                    </div> */}


                                                    {/* <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">Serving Times</label>
                                                        <div className="col-sm-9">
                                                            <Select
                                                                value={mealTimesOptions.filter(b => (mealTimes || []).indexOf(b.value) > -1)}
                                                                isMulti
                                                                onChange={selectedOptions => setMealTimes(selectedOptions ? (selectedOptions || []).map(s => s.value) : null)}
                                                                options={mealTimesOptions}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                            />
                                                        </div> */}
                                                    {/* </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">Ages Served</label>
                                                        <div className="col-sm-9">
                                                            <Select
                                                                value={agesOptions.filter(b => (agesServed || []).indexOf(b.value) > -1)}
                                                                isMulti
                                                                onChange={selectedOptions => setAgesServed(selectedOptions ? (selectedOptions || []).map(s => s.value) : null)}
                                                                options={agesOptions}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                            />
                                                        </div>
                                                    </div> */}
                                                    {/* <Control split={[3, 9]} value={targetArea} type="text" label="Target Area" onChange={e => setTargetArea(e.target.value)} /> */}

                                                    <Control split={[3, 9]} value={comments} type="textarea" label="Comments" onChange={e => setComments(e.target.value)} />
                                                </div>
                                            </div>
                                            {/* <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">Lat Lng</label>
                                                <div className="col-sm-10">
                                                    <span>{lat},{lng}</span>

                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div role="tabpanel" id="tab-2" className={"tab-pane " + (tab === "2" ? "active" : "")}>
                                        <div className="panel-body p-5">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h3 className="mb-4">Address</h3>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">Area</label>
                                                        <div className="col-sm-9">
                                                            <Select
                                                                value={zone ? zoneOptions.filter(b => b.value === zone)[0] : null}

                                                                onChange={selectedOptions => setZone(selectedOptions ? selectedOptions.value : null)}
                                                                options={zoneOptions}
                                                                // className="basic-multi-select"
                                                                classNamePrefix="select"
                                                            />
                                                        </div>
                                                    </div>
                                                    <Control
                                                        split={[3, 9]}
                                                        value={feedingAddress1}
                                                        type="text"
                                                        label="Address Line 1"
                                                        onChange={e => setFeedingAddress1(e.target.value)}
                                                    />

                                                    <Control
                                                        split={[3, 9]}
                                                        value={feedingAddress2}
                                                        type="text"
                                                        label="Address Line 2"
                                                        onChange={e => setFeedingAddress2(e.target.value)}
                                                    />






                                                    <Control
                                                        split={[3, 9]}
                                                        type="search-select"
                                                        searchUrl={`${getBaseUrl()}/postal_code`}
                                                        label="Suburb"
                                                        value={feedingSuburb}
                                                        onChange={selectedOption => {
                                                            if (selectedOption) {
                                                                let parts = selectedOption.value.split("|");
                                                                setFeedingSuburb(selectedOption.value);
                                                                setFeedingCity(parts[1])
                                                                setFeedingPostalCode(parts[2])

                                                            } else {
                                                                setFeedingSuburb(null);
                                                                setFeedingCity(null)
                                                                setFeedingPostalCode(null)
                                                            }
                                                        }}
                                                    />

                                                    <Control split={[3, 9]} value={feedingCity} type="label" label="City" />
                                                    <Control split={[3, 9]} value={feedingPostalCode} type="label" label="Postal Code" />

                                                </div>
                                                {/* <div className="col-md-6">
                                                    <h3 className="mb-4">Delivery Address</h3>
                                                    <Control
                                                        split={[3, 9]}
                                                        value={cookingAddress1}
                                                        type="text"
                                                        label="Address Line 1"
                                                        onChange={e => setCookingAddress1(e.target.value)} />

                                                    <Control
                                                        split={[3, 9]}
                                                        value={cookingAddress2}
                                                        type="text"
                                                        label="Address Line 2"
                                                        onChange={e => setCookingAddress2(e.target.value)} />

                                                    <Control
                                                        split={[3, 9]}
                                                        type="search-select"
                                                        searchUrl={`${getBaseUrl()}/postal_code`}
                                                        label="Suburb"
                                                        value={cookingSuburb}
                                                        onChange={selectedOption => {
                                                            if (selectedOption) {
                                                                let parts = selectedOption.value.split("|");
                                                                setCookingSuburb(selectedOption.value);
                                                                setCookingCity(parts[1])
                                                                setCookingPostalCode(parts[2])

                                                            } else {
                                                                setCookingSuburb(null);
                                                                setCookingCity(null)
                                                                setCookingPostalCode(null)
                                                            }
                                                        }}
                                                    />

                                                    <Control split={[3, 9]} value={cookingCity} type="label" label="City" />
                                                    <Control split={[3, 9]} value={cookingPostalCode} type="label" label="Postal Code" />

                                                </div> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>



                            <div className="hr-line-dashed"></div>
                            <div className="form-group row">
                                <ConfirmDelete confirm={confirmDelete} onDelete={onDelete} onCancel={() => setConfirmDelete(false)}>
                                    <div className="col-sm-12 col-sm-offset-2">
                                        <button className="btn btn-default" onClick={onClose}>Cancel</button>
                                        {match.params.id !== "new" && <button className="mx-1 btn btn-danger" onClick={() => setConfirmDelete(true)}>Delete</button>}
                                        <button className="ml-1 btn btn-primary" onClick={onSave}>Save Changes</button>
                                    </div>
                                </ConfirmDelete>
                            </div>
                        </div>
                    }
                </div>
            </div>

        </>
    )

}

export default MapDetail