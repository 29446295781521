import React, { useState } from 'react'
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import axios from 'axios';


function Control({ split = [2, 8], label, type, onChange, value, options = [], autoFocus = false, searchUrl }) {


    let labelDiv = `col-sm-${split[0]} col-form-label`
    let controlDiv = `col-sm-${split[1]}`




    if (type === "reactselect") {
        return (
            <div className="form-group row">
                <label className={labelDiv} >{label}</label>
                <div className={controlDiv}>
                    <Select

                        options={options}
                        onChange={onChange}
                        value={value}
                        autoFocus={autoFocus}
                    />

                </div>
            </div>
        )
    }


    if (type === "label") {
        return (
            <div className="form-group row">
                <label className={labelDiv} >{label}</label>
                <div className={controlDiv}>
                    <span>
                        {value}
                    </span>
                </div>
            </div>
        )
    }

    if (type === "search-select") {

        const loadOptions = (inputValue, callback) => {
            axios.get(`${searchUrl}?search=${inputValue}`).then(res => {
                callback(res.data.map(m => ({ value: `${m.SUBURB}|${m.AREA}|${m["STR-CODE"]}`, label: `${m.SUBURB}, ${m.AREA}, ${m["STR-CODE"]} ` })))
            }).catch(err => {
                callback(null)
            })
        };

        return (
            <div className="form-group row">
                <label className={labelDiv} >{label}</label>
                <div className={controlDiv}>
                    <AsyncSelect
                        cacheOptions
                        isClearable
                        value={value ? { value: value, label: value.split("|")[0] } : ""}
                        onChange={onChange}
                        loadOptions={loadOptions}
                    />

                </div>
            </div>
        )
    }

    if (type === "select") {
        return (
            <div className="form-group row">
                <label className={labelDiv} >{label}</label>
                <div className={controlDiv}>
                    <select
                        className="form-control"
                        onChange={onChange}
                        autoFocus={autoFocus}
                        value={value}
                    >
                        <option value="">Select {label}</option>
                        {options}
                    </select>

                </div>
            </div>
        )
    }

    if (type === "checkbox") {
        return (
            <div className="form-group row">
                <label className={labelDiv} >{label}</label>
                <div className={controlDiv}>
                    <input
                        className="ml-1 f"
                        type="checkbox"
                        onClick={onChange}
                        checked={value === true ? "checked" : ""}
                    />

                </div>
            </div>
        );

    }

    if (type === "textarea") {
        return (
            <div className="form-group row">
                <label className={labelDiv}>{label}</label>
                <div className={controlDiv}>
                    <textarea
                        rows={5}
                        autoFocus={autoFocus}
                        className="form-control"
                        onChange={onChange}
                        value={value || ""}
                    />

                </div>

            </div>
        );
    }
    return (
        <div className="form-group row">
            <label className={labelDiv}>{label}</label>
            <div className={controlDiv}>
                <input
                    type={type}
                    autoFocus={autoFocus}
                    className="form-control"
                    onChange={onChange}
                    value={value || ""}
                />

            </div>

        </div>
    );
}

export default Control