// @ts-check
import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import MapComp from './views/map/map'
import SideNav from "./components/nav";
import TopNav from "./components/topnav";
import { LoadScript } from '@react-google-maps/api';

import {
  AccountProvider,
  MainProvider,
  AccountContext,
} from "./context_providers";

const { env } = require("./services/config")
const libs = ["places"];

function AppContainer(props) {

  const { roleRoutes, email } = useContext(AccountContext)

  if (email) {



    return (
      <div id="wrapper">
        <Route path="/" component={SideNav} />
        <div id="page-wrapper" className="gray-bg">
          <TopNav {...props} />
          {env === "prod" && <Redirect path="/" to="/map" />}
          <Redirect path="/" to="/map" />
          {roleRoutes.map((route, i) => <Route key={i} path={route.path} component={route.component} />)}
        </div>
      </div>
    );
  } else {
    return (
      <div id="wrapper">
        <TopNav {...props} />

        {/* <Redirect path="/" to="/map" /> */}
        {/* <Route path="/map" render={p => <MapComp {...p} guest={true} />} /> */}
      </div>
    );
  }

}

const App = () => {

  const { email, isLoading } = useContext(AccountContext);
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          flexDirection: "row",
          paddingTop: 50,
        }}
      >
        {" "}
        <h2 style={{ color: "#dedede" }}>Loading...</h2>
      </div>
    );
  }


  return (
    <Router>
      <Route path="/" component={AppContainer} />
    </Router>
  );
};

function ProviderComposer({ contexts, children }) {
  return contexts.reduceRight(
    (kids, parent) =>
      React.cloneElement(parent, {
        children: kids,
      }),
    children
  );
}

function ContextProvider({ children }) {
  return (
    <ProviderComposer contexts={[<AccountProvider />, <MainProvider />]}>
      {children}
    </ProviderComposer>
  );
}

export default () => (
  <LoadScript
    libraries={libs}

    googleMapsApiKey="AIzaSyBZOgwKIdJSKgxVKBvtSAzqFEFUyDMJXGI"
  >
    <AccountProvider>
      <ContextProvider>
        <App />
      </ContextProvider>
    </AccountProvider>

  </LoadScript>
);
