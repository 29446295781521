//@ts-check
import React, { useContext } from "react";
import { AccountContext } from "../context_providers";

export default function SideNavHeader(props) {

  const { username, display_name, role, setUsername } = useContext(AccountContext)
  return (
    <div className="nav-header" style={{ height: 225, borderBottom: "1px solid #555" }}>
      <div className="dropdown profile-element" style={{ textAlign: "center" }}>
        <img
          style={{ width: 125 }}
          alt="image"
          className="mb-3"
          src="/img/tracking_white.svg"
        />

        <h4 style={{ color: "#ccc", textAlign: "center", fontSize: 20 }}>Data Tracking</h4>

        <ul className="dropdown-menu animated fadeInRight m-t-xs">
          <li>
            <a className="dropdown-item" href="profile.html">
              Profile
            </a>
          </li>
          <li className="dropdown-divider" />
          <li>
            <button
              onClick={() => setUsername("")}
              className="dropdown-item"
            >
              Logout
            </button>
          </li>
        </ul>
      </div>
      <div className="logo-element">ERP</div>
    </div>
  );
}


