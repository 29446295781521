export default {
    ADMIN: {
        navs: [
            { route: "/map" },
            { route: "/location" },
            { route: "/admin/lookup" },
            { route: "/admin/user" },
            { route: "/supplier" },
            { route: "/stock" },
            { route: "/delivery" },
        ]
    },
    USER: {
        navs: [
            { route: "/map" },
            { route: "/location" }
        ]
    },
    // GUEST: {
    //     navs: [
    //         { route: "/map" }
    //     ]
    // }
}