//@ts-check
import React, { useState, useEffect, useContext } from 'react'
import { GoogleMap, LoadScript, Autocomplete, Marker, Polygon, InfoWindow } from '@react-google-maps/api';
import { getApiCall, postApiCall } from '../../utilities/utilities'
import { Route } from 'react-router-dom'
import Detail from './detail';
const areas = require("../../services/mapdata.json");
import { AccountContext } from "../../context_providers";

const divStyle = {
    background: `white`,
    padding: 15
}

function MyComponent(props) {


    const { email } = useContext(AccountContext)
    const [lat, setLat] = useState(-34.06125547080887)
    const [searchFinished, setSearchFinished] = useState(false);
    const [lng, setLng] = useState(18.47993821697998)
    const [markers, setMarkers] = useState([]);
    const [feedPoint, setFeedPoint] = useState(null);
    const [area, setArea] = useState(null);
    const [mealTimeData, setMealTimeData] = useState([]);
    const [autocomplete, setAutoComplete] = useState(null);
    const [addMarker, setAddMarker] = useState(false);
    const [manualSelect, setManualSelect] = useState(false);
    const [lookupData, setLookupData] = useState(false);

    let center = { lat, lng };


    useEffect(() => {
        loadMarker();
        loadLookups();
        if (location.pathname === "/map") {
            setSearchFinished(false)
            loadMarkers()
        }
    }, [location.pathname])



    async function loadMarker() {
        if (location.search) {

            let _id = location.search.split("=")[1]
            let res = await getApiCall(`/location/${_id}`);
            let point = res.data;
            setFeedPoint(point);
            setArea(null);
            setLat(point.lat)
            setLng(point.lng)
            if (props.guest) {
                return;
            }

        }
    }

    async function loadLookups() {
        let res = await getApiCall(`/lookup`)
        setLookupData(res.data);
    }



    async function loadMarkers() {
        let res = await getApiCall("/map/location")
        let markers = res.data
        setMarkers(markers);
    }

    function onAutoCompleteLoad(_autocomplete) {
        setAutoComplete(_autocomplete)
    }

    function onPlaceChanged() {
        if (autocomplete !== null) {
            const { location } = autocomplete.getPlace().geometry;
            let _lat = location.lat();
            let _lng = location.lng();
            setLat(_lat);
            setSearchFinished(true);
            setLng(_lng);
            if (email) {
                setAddMarker(true)
            }

        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }


    function onPolygonClicked(p, area) {
        if (manualSelect) {
            onMapClick(p)
            return;
        }
        setFeedPoint(null);
        setLat(p.latLng.lat())
        setLng(p.latLng.lng())
        setArea(area)
    }

    async function onMarkerClick(feedpoint, p) {
        if (manualSelect) {
            return;
        }
        let res = await getApiCall(`/map/location/${feedpoint._id}`);
        setFeedPoint(res.data);
        setArea(null);
        setLat(p.latLng.lat())
        setLng(p.latLng.lng())
        if (props.guest) {
            return;
        }
    }


    function onDragEnd(data) {
        let _lat = data.latLng.lat();
        let _lng = data.latLng.lng();
        setLat(_lat);
        setLng(_lng);

        props.history.replace("/map/new", { lat: _lat, lng: _lng })
    }

    function onMoreInfo() {
        props.history.push(`/location/${feedPoint._id}`)
    }

    async function onLocationSave(name, contactPerson, category) {
        let _marker = {
            name,
            category: category || null,
            contactPerson,
            lat,
            lng
        }
        let res = await postApiCall("/location", _marker)
        setFeedPoint(res.data)
        await loadMarkers()
        setAddMarker(false);
        setSearchFinished(false);
        setManualSelect(false)
    }

    function renderAutoComplete() {

        if (email && !manualSelect) {

            return (
                <Autocomplete

                    bounds={new google.maps.LatLngBounds(
                        { lat: -34.959015, lng: 15.254412 },  //se
                        { lat: -21.965184, lng: 35.319733 }, //nw
                    )
                    }
                    onLoad={onAutoCompleteLoad}
                    onPlaceChanged={onPlaceChanged}
                >
                    <input
                        type="text"
                        className="map-autocomplete"
                        placeholder="&#xF002; Add Location..."
                    />

                </Autocomplete>
            )
        }
    }

    function renderFeedPointInfo() {

        if (feedPoint) {
            return (
                <InfoWindow
                    onCloseClick={() => setFeedPoint(null)}
                    position={{ lat, lng }}
                >
                    <div style={divStyle}>
                        <h3 style={{ marginBottom: 0 }}>{feedPoint.name}</h3>
                        <small>{feedPoint.category && feedPoint.category.name}</small>

                        <p className="mt-2"><strong>Contact:</strong> {feedPoint.contactPerson}</p>
                        {/* {lookupData.mealtype && lookupData.mealtype.length > 0 && <div><strong>Meals:</strong> {(lookupData.mealtype.filter(m => (feedPoint.mealTypes || []).indexOf(m._id) > -1)).map(m => m.name).join(",")}</div>} */}
                        {/* {lookupData.mealtime && lookupData.mealtime.length > 0 && <div><strong>Serving Times:</strong> {(lookupData.mealtime.filter(m => (feedPoint.mealTimes || []).indexOf(m._id) > -1)).map(m => m.name).join(",")}</div>} */}
                        {/* {lookupData.day && lookupData.day.length > 0 && <div><strong>Days:</strong> {(lookupData.day.filter(m => (feedPoint.days || []).indexOf(m._id) > -1)).map(m => m.name).join(",")}</div>} */}
                        {email && <button className="mt-1" onClick={onMoreInfo}>More Info</button>}
                    </div>
                </InfoWindow>
            )
        }
    }


    function renderAreaInfo() {

        if (area) {
            return (
                <InfoWindow
                    onCloseClick={() => setArea(null)}
                    position={{ lat, lng }}
                >
                    <div style={divStyle}>
                        <h3>{area.name}</h3>
                    </div>
                </InfoWindow>
            )
        }
    }

    function onMapClick(p) {
        if (!manualSelect) {
            return
        }
        let _lat = p.latLng.lat();
        let _lng = p.latLng.lng();
        setSearchFinished(true);
        setLat(_lat);
        setLng(_lng);
        setAddMarker(true)

    }

    function renderSearchedPin() {
        if (searchFinished) {
            return <Marker
                animation={google.maps.Animation.BOUNCE}
                draggable={true}
                onDragEnd={onDragEnd}
                position={center}
            />
        }
    }

    function renderMarkers() {
        return markers.map((m, i) => {

            return <Marker

                key={i}
                title={m.name}
                icon={m.category && m.category.icon ? {


                    url: m.category.icon
                } : null}
                onClick={(p) => onMarkerClick(m, p)}
                position={{ lat: parseFloat(m.lat), lng: parseFloat(m.lng) }}
            />
        });
    }

    function renderAreas() {

        return areas.map((a, i) => (
            <Polygon
                key={i}
                paths={a.points}
                onClick={(data) => onPolygonClicked(data, a)}
                options={{
                    fillColor: "#" + a.fill,
                    fillOpacity: 0.2,
                    strokeOpacity: 1,
                    strokeWeight: 0.2,
                    clickable: true,
                    draggable: false,
                    editable: false,
                    geodesic: false,
                    zIndex: 1
                }}
            >
            </Polygon>
        ));

    }

    function renderManuallyAddLocation() {
        if (email) {
            return (<div style={{
                backgroundColor: "white",
                zIndex: 1,
                borderRadius: 6,
                position: "absolute",
                top: 50, display: "flex",
                justifyItems: "center",
                height: 37,
                padding: 5,
                border: "1px solid #dedede",
                left: "50%",
                width: 182,
                marginLeft: -100
            }}>
                <p className="pt-1">Manually Add Location</p>
                <input className="ml-1" type="checkbox" checked={manualSelect ? "checked" : ""} onChange={() => {
                    setManualSelect(!manualSelect)
                    setFeedPoint(null)
                }} />

            </div>
            )
        }
    }


    return (
        <>

            <GoogleMap
                onBoundsChanged={data => console.log(data)}
                onChange={data => console(data)}
                onClick={onMapClick}
                mapContainerStyle={{ width: "100%", height: "100%" }}
                center={center}
                zoom={15}
            >
                {renderManuallyAddLocation()}
                {renderAutoComplete()}
                {renderFeedPointInfo()}
                {renderAreaInfo()}
                {renderSearchedPin()}
                {renderMarkers()}
                {renderAreas()}
            </GoogleMap>
            {addMarker && <Detail
                lookupData={lookupData}
                onSave={onLocationSave}
                onClose={() => {
                    setAddMarker(false)
                    if (!manualSelect) {

                        document.getElementsByClassName("map-autocomplete")[0].value = ""
                    }
                    setSearchFinished(false)
                    setManualSelect(false)

                }} />}
        </>
    )
}


export default MyComponent