import React from 'react';
import ReactDOM from 'react-dom';
import AppContainer from './app_container';
import "react-table/react-table.css";
import "./styles/custom.css";

const rootEl = document.getElementById('root');
ReactDOM.render(<AppContainer />, rootEl);
if (module.hot) {
    module.hot.accept('./app_container', function () {
        ReactDOM.render(
            <AppContainer />
            , rootEl);
    });
}