import Lookup from "./views/admin/lookup";
import Location from "./views/location";
import Stock from "./views/stock";
import Distribution from "./views/distribute";
import Supplier from "./views/supplier";
import User from "./views/admin/user";
import MapComp from "./views/map";


export default [
    { path: "/map", component: MapComp },
    { path: "/location", component: Location },
    { path: "/admin/user", component: User },
    { path: "/admin/lookup", component: Lookup },
    { path: "/supplier", component: Supplier },
    { path: "/stock", component: Stock },
    { path: "/delivery", component: Distribution },
]