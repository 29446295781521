// @ts-check
import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import ReactTable from 'react-table';
import numeral from 'numeral';
import moment from 'moment';
import { getApiCall } from "../../utilities/utilities";
import { AccountContext, MainContext } from "../../context_providers";


function List({ history, location }) {

    const { email } = useContext(AccountContext)
    const { suppliers, lookupData, loadLookups, loadSuppliers, stockData, loadStockData, stockQuery, setStockQuery } = useContext(MainContext)

    const { stockItem, supplier, page } = stockQuery;
    const { data, totalCount, totalPages, isLoading } = stockData

    useEffect(() => {
        loadLookups();
        loadSuppliers();
        loadStockData();
    }, []);


    useEffect(() => {
        loadStockData();
    }, [page, supplier, stockItem])





    function search(e) {

        const { type, name, value } = e.target;


        if (type === "select-one") {

            switch (name) {
                case "stockItem":
                    setStockQuery({ ...stockQuery, stockItem: value, page: 1 })

                    break;
                case "supplier":
                    setStockQuery({ ...stockQuery, supplier: value, page: 1 })
                    break;
                default:
                    break;
            }
        }
    }


    function prev() {
        setStockQuery({ ...stockQuery, page: page - 1 })
    }

    function next() {
        setStockQuery({ ...stockQuery, page: page + 1 })
    }

    function onAdd() {
        history.push("/stock/new");
    }


    function onRowClick(item) {
        return;
        history.push(`/stock/${item._id}`)
    }

    function Pager() {
        return (
            <>
                <button disabled={page <= 1} className="btn btn-xs btn-success" onClick={prev} >{"< Prev"}</button>
                <span className="mx-2">Page: {page} of {totalPages}</span>
                <button disabled={page >= totalPages} className="btn btn-xs btn-success" onClick={next} >{"Next >"}</button>
            </>
        )
    }


    return (
        <>
            <div className="row wrapper border-bottom white-bg page-heading">

                <div className="col-lg-10 pl-4">
                    <h2>{"Stock"}</h2>
                    <small>{`Total Count: ${totalCount || ""}`}</small>
                </div>

                <div className="col-lg-2 pt-4">
                    <button
                        onClick={onAdd}
                        className="btn btn-primary pull-right" >
                        <i className="fa fa-plus" /> Add Stock</button>
                </div>
            </div>
            <div className="scrollable" style={{ height: "calc(100% - 110px)" }}>
                <div className="ibox animated fadeInLeft mt-3">
                    <div className="ibox-content" style={{ paddingBottom: 38 }}>
                        <div className="pull-right mb-2" style={{ display: "flex", flexDirection: "row", alignItems: 'center', fontSize: 14 }}>

                            {Pager()}
                        </div>
                        <table className="display table table-striped table-bordered locations" style={{ width: "100%", fontSize: 12 }}>
                            <thead className="header">

                                <tr style={{ color: "white", fontSize: 10 }}>
                                    <th style={{ width: 10 }}></th>
                                    <th style={{ width: 250 }}>
                                        <select style={{ color: "black", fontSize: 10, height: 29 }}
                                            name="stockItem"
                                            className="form-control"
                                            value={stockItem}
                                            onChange={search}>
                                            <option value="">All Items</option>
                                            {(lookupData.stock_item || []).map((item, i) => <option key={i} value={item._id}>{item.name}</option>)}
                                        </select>
                                    </th>

                                    <th style={{ width: 100 }}>Qty</th>
                                    <th >
                                        <select style={{ color: "black", fontSize: 10, height: 29 }}
                                            name="supplier"
                                            className="form-control"
                                            value={supplier}
                                            onChange={search}>
                                            <option value="">All Suppliers</option>
                                            {(suppliers || []).map((item, i) => <option key={i} value={item._id}>{item.name}</option>)}
                                        </select>
                                    </th>
                                    <th style={{ width: 150 }}>
                                        Date Captured
                                    </th>

                                    <th style={{ width: 300 }}>
                                        Note
                                    </th>


                                </tr>
                            </thead>
                            <tbody >
                                {data.map((item, i) => {
                                    return (
                                        <tr style={{ cursor: "pointer" }} key={i} onClick={() => onRowClick(item)}>
                                            <td>{i + 1 + ((page - 1) * 20)}</td>
                                            <td>{item.stockItem && item.stockItem.name}</td>
                                            <td style={{ textAlign: "right" }}>{item.qty}</td>
                                            <td>{item.supplier && item.supplier.name}</td>
                                            <td>{item.dateCaptured && moment(item.dateCaptured).format("DD-MMM-YYYY HH:mm:ss")}</td>
                                            <td>{item.note}</td>

                                        </tr>
                                    )
                                })}
                            </tbody>

                        </table>
                        <div className="pull-right mb-2" style={{ display: "flex", flexDirection: "row", alignItems: 'center', fontSize: 14 }}>

                            {Pager()}
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
}

export default List;
