//@ts-check
import React from 'react';
import { Route } from 'react-router-dom'
import List from './list';
import Detail from './detail';



function Location() {

    return (
        <>
            <Route exact path="/admin/user/:id" component={Detail} />
            <Route exact path="/admin/user/" component={List} />
        </>
    );
}


export default Location;