//@ts-check
import React from 'react';
import { Route } from 'react-router-dom'
import Map from './map';
import { GoogleMap } from '@react-google-maps/api';



function Location() {

    return (
        <>
            <Route path="/map" component={Map} />

        </>
    );
}


export default Location;